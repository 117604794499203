// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"dev1-setup-de97cfebfb7d5a186ef6520d2033ec78b657c059"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/sv";async function loadSentry() {
  // @TODO: IMPORTANT this settings are for PRODUCTION testing only
  // In order to make it easier to test Sentry during pre-production
  const { init, replayIntegration: Replay } = await import('@sentry/nextjs')
  //const filtersTransaction = [/^Layout Server Component \(\/\)/i]
  const filtersServer = [
    /LT\-GIT\-010/i,
    /LT\-GIT\-025/i,
    /LT\-GIT\-035\.local/i,
    /LT\-GIT\-035\.localdomain/i,
    /LT\-GIT\-032\.local/i,
    /LT\-GIT\-035\.localdomain/i,
    /LT\-GIT\x049\.local/i,
    /MacBook-Pro-som-tillhor-Dennis\.local/i,
    /Filips-MacBook-Air\.local/i,
    /mbpsomtordennis\.lan/i,
    /buildkitsandbox/i,
    /guilherme-laptop/i,
  ]

  init({
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT || 'development',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    // An array of strings or regexps that'll be used to ignore specific errors based on their type/message
    ignoreErrors: ['error while getting userInfo'],
    //ignoreTransactions: ["partial/match", /^Exact Transaction Name$/],
    denyUrls: [
      '*.prismic.io',
      'localhost',
      /^https?:\/\/localhost(:[0-9]+)?(\/[^\s]*)?$/i,
      /^https?:\/\/gtm-kkntq2t-ntvlz.uc.r.appspot.com\/g\/([^\s]*)?$/i,
      /0\.0\.0\.0/i,
      /127\.0\.0\.1/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    //
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate:
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? 0.2 : 0.1,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    //
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate:
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? 0.2 : 0.1,
    release: process.env.NEXT_PUBLIC_VERSION,
    integrations: ['production'].includes(
      process.env.NEXT_PUBLIC_ENVIRONMENT || '',
    )
      ? [
          Replay({
            maskAllText: true,
            blockAllMedia: true,
            unmask: ['.sentry-unmask'],
            maxReplayDuration: 1000 * 60 * 4,
            networkDetailAllowUrls: [
              'https://luckycasino.com/sv/missing-info/phone-email',
              'https://luckycasino.com/billfold-api/player/updateFields'
            ],
          }),
        ]
      : [],
    // Data Scrubbing
    beforeSend(event) {
      //console.log({ event })
      // if (event.transaction) {
      //   if (
      //     filtersTransaction.find((filter) => event.transaction.match(filter))
      //   )
      //     return null
      // }
      if (event?.server_name) {
        if (filtersServer.find((filter) => event?.server_name?.match(filter)))
          return null
      }
      if (event?.user) {
        deleteSensitiveData(event.user)
      }
      if (event?.breadcrumbs) {
        event.breadcrumbs.forEach((crumb) => {
          if (crumb.data) {
            deleteSensitiveData(crumb.data)
          }
        })
      }
      return event
    },
    //https://docs.sentry.io/platforms/javascript/configuration/options/
    debug: false,
  })
}

loadSentry()

function deleteSensitiveData(data) {
  delete data?.email
  delete data?.username
  delete data?.ip_address
  delete data?.ip
}
